export const callCenterName = 'GBCallCenter'
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const callCenterTimezone = 'Europe/London'

export const formatPhoneNumber = (phoneNumber, fallbackPhoneNumber) => {
  const formattedNumber = phoneNumber ? phoneNumber.replace(' ', '') : fallbackPhoneNumber || ''
  if (!formattedNumber) return ''

  return (
    formattedNumber.substr(0, 4) + ' ' + formattedNumber.substr(4, 3) + ' ' + formattedNumber.substr(7, 4)
  )
}

// we are validating only min and max length of digits - refer to TBCR-604
export const phonePattern = '^\\d{10,12}$'

export const showAppointmentTimezone = false
